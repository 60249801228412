<template>
  <div class="w-100 h-100">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      chartId="accessChart"
      height="100%"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            text: this.isAdmin ? "各行政区接入情况" : "管辖区各镇街道接入情况",
            textStyle: {
              color: "#11d0c9"
            },
            top: "2%"
          },
          tooltip: {
            show: true,
            confine: true,
            trigger: "axis",
            formatter: (params) => {
              var res = `${params[0].axisValue}<br/>`;
              res += `${params[0].marker}${params[0].seriesName}：${params[0].value}座<br/>`;
              res += `${params[1].marker}${params[1].seriesName}：${params[1].value}台`;
              return res;
            }
          },
          legend: {
            data: ["楼宇数", "设备总数"],
            textStyle: {
              color: "#11d0c9"
            },
            top: "0%",
            right: "5%",
            orient: "vertical"
          },
          grid: {
            width: "70%",
            height: "90%",
            top: "10%",
            left: "15%"
          },
          xAxis: {
            type: 'value',
            axisLine: {show: false},
            axisLabel: {show: false}
          },
          yAxis: {
            type: 'category',
            data: newData.category,
            axisLabel: {
              color: '#11d0c9'
            }
          },
          series: [
            {
              name: "楼宇数",
              type: "bar",
              barMaxWidth: 30,
              itemStyle: {
                normal: {
                  color: '#0CB2FE'
                }
              },
              label: {
                show: true,
                position: "right",
                color: "#FFFFFF"
              },
              data: newData.bar1Data
            },
            {
              name: "设备总数",
              type: "bar",
              barGap: 0,
              barMaxWidth: 30,
              itemStyle: {
                normal: {
                  color: '#FFCC33'
                }
              },
              label: {
                show: true,
                position: "right",
                color: "#FFFFFF"
              },
              data: newData.bar2Data
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>