<template>
  <div class="w-100 h-100" style="display: flex">
    <Echart
      :options="options"
      chartId="eventsChart2"
      height="100%"
      width="33%"
    ></Echart>
    <Echart
      :options="Temperatures"
      chartId="eventsChart1"
      height="100%"
      width="33%"
    ></Echart>
    <Echart
      :options="smokeConcentrations"
      chartId="eventsChart3"
      height="100%"
      width="33%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      Temperatures: {},
      smokeConcentrations: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
    eventType: {
      type: String,
      default: "",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        console.log("eventChart newData", newData);

        this.Temperatures = {
          title: {
            text: "温度",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["迷宫温度", "环境温度"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            show: false,
          },
          xAxis: {
            type: "category",
            data: newData.times,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "迷宫温度",
              data: newData.insideTemperatures,
              type: "line",
              smooth: true,
              markPoint: {
                data: [
                  { type: "max", name: "Max" },
                  { type: "min", name: "Min" },
                ],
              },
              markLine: {
                data: [{ type: "average", name: "Avg" }],
              },
            },
            {
              name: "环境温度",
              data: newData.outsideTemperatures,
              type: "line",
              smooth: true,
              markPoint: {
                data: [
                  { type: "max", name: "Max" },
                  { type: "min", name: "Min" },
                ],
              },
              markLine: {
                data: [{ type: "average", name: "Avg" }],
              },
            },
          ],
        };
        this.options = {
          title: {
            text: "湿度",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["湿度"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            show: false,
          },
          xAxis: {
            type: "category",
            data: newData.times,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "湿度",
              data: newData.humiditys,
              type: "line",
              smooth: true,
            },
          ],
        };
        this.smokeConcentrations = {
          title: {
            text: "烟雾浓度",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["烟雾浓度"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            show: false,
          },
          xAxis: {
            type: "category",
            data: newData.times,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "烟雾浓度",
              data: newData.smokeConcentrations,
              type: "line",
              smooth: true,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
