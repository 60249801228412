<template>
  <div class="w-100 h-100" >
    <Chart  :id="title" :cdata="cdata" :title="title" :eventType="eventType" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {};
  },
  components: {
    Chart,
  },
  mounted () {
    
  },
  props: {
    title:{
      type: String,
      default: ""
    },
    eventType:{
      type: String,
      default: ""
    },
    cdata: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
</style>