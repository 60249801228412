<template>
  <div id="centreLeft1">
    <div class="d-flex jc-center flex-1 h-100">
    <div class=" d-flex flex-column " style="position:relative;ovorflow:hidden;">
        <chinaMapChart />
      </div>
    </div>
  </div>
</template>

<script>
// import chinaMapChart from "@/components/echart/chinaMapChart";
import chinaMapChart from "@/components/echart/allMap.vue";
export default {
  data() {
    return {};
  },
  components: {
    chinaMapChart
    // allMap

  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  overflow:hidden;
  .bg-color-black {
    width: 100%;
    height: 100%;
    .data {
      &::before, &::after {
        content: '';
        display: block;
      }
      .text {
        color: #11d0c9;
        font-size: 0.3rem;
        line-height: 0.45rem;
        text-align: center;
      }
    }
  }
}
</style>