<template>
  <div class="w-100 d-flex detail">
    <div class="d-flex flex-column ai-start h-100" style="width:50%">
      <el-input class="mb-2" v-model="keyword" placeholder="输入关键字搜索" @change="search"></el-input>
      <div class="w-100 h-100 list d-flex flex-column ai-start">
        <div class="list-text w-100" :class="{active: index==i}" v-for="(item, i) in list" :key="i" @click="index=i">{{item.name}}</div>      
      </div>
    </div>
    <div v-if="this.list.length != 0" class="flex-1 d-flex flex-column ai-start h-100 ml-5">
      <span class="info-text">楼宇名称：{{this.list[this.index].name}}</span>
      <span class="info-text">楼宇位置：{{this.list[this.index].address}}</span>
      <span class="info-text">楼宇联系人：{{this.list[this.index].contactPerson}} {{this.list[this.index].contactPersonPhone}}</span>
      <span class="info-text">维保人：{{this.list[this.index].maintainer}} {{this.list[this.index].maintainerPhone}}</span>
      <span class="info-text">接入设备总数：{{this.list[this.index].totalDevicesCount}}</span>
      <div class="btn" @click="showDeviceList">查看设备列表</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
      list: [],
      index: 0,
      show: false
    }
  },
  // props: ['info'],
  mounted () {
    this.getList();
  },
  methods: {
    getList () {
      this.$get(this.$apis.fowerList, {
        keyword: this.keyword,
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res && res.code === 0) {
          this.index = 0;
          this.list = res.data.list;
        }
      });
    },
    search (e) {
      this.keyword = e;
      this.getList();
    },
    showDeviceList () {
      this.$emit('deviceList', this.list[this.index]);
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  height: 5rem;
  overflow: hidden;
  .info-text {
    color: #333;
    font-size: 0.25rem;
    line-height: 0.4rem;
  }
  .btn {
    padding: 0.2rem;
    font-size: 0.25rem;
    background-color: #568aea;
    color: #FFF;
    border-radius: 0.05rem;
    margin-top:0.2rem;
  }
  ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
  .list {
    overflow: auto;
    &-text {
      text-align: center;
      font-size: 0.25rem;
      line-height: 0.5rem;
      color: #333;
      border: 1px solid rgba(0,0,0,.1);
      margin-bottom: 0.1rem;
    }
    .active {
      background-color: #568aea;
      color: #FFF;
      border: 1px solid #568aea;
    }
  }
}
</style>