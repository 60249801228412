<template>
  <bm-overlay
    ref="customOverlay"
    pane="markerPane"
    @draw="draw"
    class="sample"
    @mouseover.native="show = true"
    @mouseleave.native="show = false">
    <img style="width:30px;height:30px;" :src="require(`../assets/status-${this.info.deviceStatus}.png`)" />
    <div v-if="show" class="alarm-detail d-flex flex-column ai-start">
      <span class="alarm-detail-title">告警信息</span>
      <div class="d-flex">
        <div class="d-flex flex-column" style="width:3rem;">
          <span class="alarm-detail-text">告警时间：{{this.info.alarmTime}}</span>
          <span class="alarm-detail-text">告警设备：{{this.info.productName}}</span>
          <span class="alarm-detail-text">告警类型：{{this.info.deviceStatus == 1 ? '正常' : this.info.deviceStatus == 2 ? '离线' : this.info.deviceStatus == 3 ? '预警' : this.info.deviceStatus == 4 ? '火警' : '故障'}}</span>
          <span class="alarm-detail-text">楼宇名称：{{this.info.tower}}</span>
          <span class="alarm-detail-text">楼栋：{{this.info.floor}}</span>
          <span class="alarm-detail-text">安装位置：{{this.info.installPositionName}}</span>
          <span class="alarm-detail-text">负责人信息：{{this.info.memberId}}</span>
        </div>
        <div class="d-flex flex-column">
          <img class="alarm-detail-img" style="width:2.5rem;height:2.5rem;" :src="info.sceneImgList[0]" />
          <div class="alarm-detail-btn" @click="checkEscapeRoutes">查看逃生路线</div>
        </div>
      </div>
    </div>
  </bm-overlay>
</template>

<script>
// import BmOverlay from 'vue-baidu-map/components/overlays/Overlay.vue';
export default {
  components: {
    // BmOverlay
  },
  data() {
    return {
      show: false
    }
  },
  props: ['info'],
  watch: {
    info: {
      handler () {
        this.$refs.customOverlay.reload()
      },
      deep: true
    }
  },
  methods: {
    draw ({el, BMap, map}) {
      const {longitude, latitude} = this.info;
      const pixel = map.pointToOverlayPixel(new BMap.Point(longitude, latitude))
      el.style.left = pixel.x - 15 + 'px'
      el.style.top = pixel.y - 15 + 'px'
    },
    checkEscapeRoutes () {
      this.$emit('escapeRoutes', this.info.escapeRoutesImage);
    }
  }
}
</script>

<style lang="scss" scoped>
.sample {
  width: 30px;
  height: 30px;
  position: absolute;
}
.alarm-detail {
  color: #333;
  padding: 0.2rem;
  position: absolute;
  top: 30px; right: 50%;
  transform: translateX(50%);
  background-color: #FFF;
  box-shadow: 0 0 1px 1px rgba(0,0,0,.1);
  border-radius: 0.1rem;
  &-title {
    font-size: 0.3rem;
    margin-bottom: 0.2rem;
  }
  &-text {
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  &-img {
    box-shadow: 0 0 1px 1px rgba(0,0,0,.1);
    margin-bottom: 0.2rem;
  }
  &-btn {
    width: 2.5rem;
    height: 0.3rem;
    line-height: 0.3rem;
    text-align: center;
    background-color: #568aea;
    color: #FFF;
    border-radius: 0.05rem;
    cursor: pointer;
  }
}
</style>