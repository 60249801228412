<template>
  <div id="">
     <el-dialog
      :visible.sync="dialogVisible"
      title=""
      width="70%"
      ref="jgFileViewerDialog"
      append-to-body
      custom-class="jg-file-viewer"
      @close="$emit('close',true)"
    >
      <div class="">
        <div >
        <span>状态：</span>
         <el-select v-model="deviceStatus" placeholder="请选择">
          <el-option
            v-for="item in deviceStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <span>&nbsp;&nbsp;</span>
        <el-button type="primary" @click="getSqDeviceList()">搜索</el-button>
        </div>

        <el-table :data="DeviceList" style="width: 100%" height="500">
          <el-table-column prop="deviceId" label="设备编号" width="100"> </el-table-column>
          <el-table-column prop="productName" label="名称"> </el-table-column>
          <el-table-column prop="sceneImgs" label="现场照片">
           <template slot-scope="scope">
              <img :src="scope.row.sceneImgs" style="width:80px;height:80px;" />
          </template>
          </el-table-column>
          <el-table-column prop="deviceStatusName" label="状态"></el-table-column>
          <el-table-column prop="alertsDeviceStatus" label="告警状态">
           <template slot-scope="scope">
          <div>{{scope.row.alertsDeviceStatus?'告警':'正常'}}</div>
          </template>
          </el-table-column>
          
          <el-table-column prop="region" label="行政单位">
          <template slot-scope="scope">
          <div>
          <p>{{scope.row.province}}</p>
          <p>{{scope.row.city}}</p>
          <p>{{scope.row.region}}</p>
          <p v-if="scope.row.town">{{scope.row.town}}</p>
          </div>
          </template>
          </el-table-column>
          <el-table-column prop="" label="具体位置">
          <template slot-scope="scope">
          <div>
          <p>门牌：{{scope.row.houseNumber}}</p>
          <p>楼层：{{scope.row.floorNumber}}</p>
          <p>房间：{{scope.row.roomNum}}</p>
          <p>方位：{{scope.row.installPositionName}}</p>
          </div>
          </template>
          </el-table-column>
          
          <el-table-column prop="detailAddress" label="地址" fixed="right"> </el-table-column>
        </el-table>

        <el-pagination
          :page-size="pageSize"
          :pager-count="11"
          layout="prev, pager, next"
          :total="DeviceTotal"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible:false,
      total: 0,
      untreatedCount: 0,
      alreadyProcessedCount: 0,
      list: [],
      // 设备状态:1->正常;2->离线;3->预警;4->火警;5->故障;10->救援告警;
      deviceStatusOptions: [{
          value: 1,
          label: '正常'
        }, {
          value: 2,
          label: '离线'
        }, {
          value: 3,
          label: '预警'
        }, {
          value: 4,
          label: '火警'
        }, {
          value: 5,
          label: '故障'
        }, {
          value: 10,
          label: '救援告警'
        }
        ],
        deviceStatus: '',
        DeviceList:[],
        DeviceTotal:0,
        pageSize:10,
    };
  },
  components: {},
  mounted() {
    if(this.devStatus){
        this.deviceStatus = this.devStatus
    }
    this.getSqDeviceList();
    this.dialogVisible = true;
  },
  props: {
    devStatus:{
      type: String,
      default:''
    },
    obj: {
      type: Object,
      default () {
        return {}
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    obj: {
      handler() {
      
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleCurrentChange(e){
      console.log("click handleCurrentChange", e);
      this.getSqDeviceList('',e)
    },
    getSqDeviceList(item,e=1){
    let dashUser = localStorage.getItem("dashUser");
    let dashUrl = localStorage.getItem("dashUrl") + "/api/dev/devonlinedevice/page";
      let params = {
        page: e,
        limit: this.pageSize,
        // squareCabinId: item.squareId,
        regionType:dashUser.type,
        deviceStatus: this.deviceStatus
      };
      switch (dashUser.type) {
          case 1:
            params.provinceId = dashUser.provinceId;
            break;
          case "city":
            params.cityId = dashUser.cityId;
            break;
          case "country":
            params.countryId = dashUser.countryId;
            break;
        }
      this.$get(dashUrl, params, {}, true).then(({ data: res }) => {
        console.log("getSquarecabinList data ", res);
        if (res) {
          this.DeviceList = res.list;
          this.DeviceTotal = res.total;
        }
      })
    },
  }
};
</script>

<style lang="scss">
.alarm-address {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-time {
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-device {
  font-size: 0.15rem;
}
</style>
<style lang="scss" scoped>
#alarmList {
  padding: 0.2rem;
  width: 100%;
  // height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  .bg-color-black {
    width: 100%;
    height: 100%;
    position: relative;
    .top-data {
      position:absolute;
      top:-0.8rem;
      right: 0;
      height:0.5rem;
      &-text {
        color: #11d0c9;
        font-size: 0.1rem;
        font-weight: bold;
        line-height:0.1rem;
      }
    }
  }
  .text {
    font-size: 0.2rem;
    color: #11d0c9;
  }
  .btn {
    height: 100%;
    white-space: nowrap;
    font-size: 0.1rem;
    color: #c3cbde;
  }
  .body-box {
    height: 100%;
    overflow: hidden;
  }
}
</style>