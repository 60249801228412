<template>
  <div id="monitor">
    <div class="bg-color-black">
      <div class="d-flex ai-center py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="align-justify" scale="0.8"></icon>
        </span>
        <div class="d-flex ai-center flex-1">
          <span class="text mx-2">接入情况</span>
        </div>
        <div class="flex-1"></div>
      </div>

      <div  class="list-box d-flex flex-column">
        <div class="d-flex flex-ai-center list-box-item" 
          v-for="(item, i) in list" 
          :key="i"
         >
        <div class="flex-1 list-box-text" >{{item.regionName}}</div>
        <div class="flex-1 list-box-text" >设备接入数： {{item.totalDevicesCount}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      newUrl: '',
      isList: true,
      list:[]
    };
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.getData();
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.getData();
    },120000);
  },
  destroyed() {
    this.dateTimer && clearInterval(this.dateTimer);
    this.timer && clearInterval(this.timer);
  },
  methods: {
    getData(){
      let baseUrl = localStorage.getItem('baseUrl');
      // if(baseUrl.indexOf('dash')!=-1){
      let dashUser = localStorage.getItem('dashUser');
          dashUser =  JSON.parse(dashUser);
          let dashUrl = baseUrl + '/api/dashboard/devicesStaticByRegion';
         let params = {
           regionType:dashUser.type
        }
        this.$get(dashUrl, params,{},true)
          .then(({ data: res }) => {
            res.sort((a, b) => {
              return b.totalDevicesCount - a.totalDevicesCount;
            });
            this.list = res;
          })
    // }

    }
  }
};
</script>

<style lang="scss" scoped>
#monitor {
  padding: 0.4rem 0.2rem 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  display: flex;
  .bg-color-black {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    .text {
      font-size: 0.2rem;
      color: #11d0c9;
    }
    .btn {
      padding: 0.1rem 0.2rem;
      font-size: 0.2rem;
      color: #c3cbde;
      border-radius: 0.05rem;
      background-color: #568aea;
      cursor: pointer;
    }
    .list-box {
      position: absolute;
      top: 0.75rem;bottom: 0;left: 0;right: 0;
      background-color: #0f1325;
      overflow-y: auto;
      &-item {
        width: 100%;
        padding: 0.1rem 0.3rem;
        cursor: pointer;
      }
      &-text {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.2rem;
        color: #11d0c9;;
        padding:2px 0;
      }
    }
  }
}
</style>