<template>
  <div id="alarmList" >
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex ai-center py-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="fire"></icon>
        </span>
        <div class="d-flex ai-center flex-1">
          <span class="text mx-2">方舱列表</span>
        </div>
      </div>
      <div class="d-flex jc-center flex-1 body-box">
        <dv-scroll-board
          ref="alarmRef"
          :config="config"
          style="width: 100%; height:100%; cursor: pointer"
          @click="click"
        />
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :title="currentSqCabin.title"
      width="70%"
      ref="jgFileViewerDialog"
      append-to-body
      custom-class="jg-file-viewer"
    >
      <div class="">
        <div>
        <p>
        <span>方舱状态：{{currentSqCabin.status=='ONLINE'?'在线':'待机'}}</span>
        <span style="margin:0 40px;">烟感数：{{currentSqCabin.smokeSensationCount}}</span>
        <span v-if="currentSqCabin.town">方舱街道：{{currentSqCabin.town}}</span>
        </p>
        <br v-if="currentSqCabin.town"/>
        <p>方舱位置：{{currentSqCabin.location}}</p>
        </div>

        <el-table :data="sqDeviceList" style="width: 100%" height="500">
          <el-table-column prop="deviceId" label="设备编号" width="100"> </el-table-column>
          <el-table-column prop="productName" label="名称"> </el-table-column>
          <el-table-column prop="sceneImgs" label="现场照片">
           <template slot-scope="scope">
              <img :src="scope.row.sceneImgs" style="width:80px;height:80px;" />
          </template>
          </el-table-column>
          <el-table-column prop="deviceStatusName" label="状态"></el-table-column>
          <el-table-column prop="alertsDeviceStatus" label="告警状态">
           <template slot-scope="scope">
          <div>{{scope.row.alertsDeviceStatus?'告警':'正常'}}</div>
          </template>
          </el-table-column>
          
          <el-table-column prop="region" label="行政单位">
          <template slot-scope="scope">
          <div>
          <p>{{scope.row.province}}</p>
          <p>{{scope.row.city}}</p>
          <p>{{scope.row.region}}</p>
          <p v-if="scope.row.town">{{scope.row.town}}</p>
          </div>
          </template>
          </el-table-column>
          <el-table-column prop="" label="具体位置">
          <template slot-scope="scope">
          <div>
          <p>门牌：{{scope.row.houseNumber}}</p>
          <p>楼层：{{scope.row.floorNumber}}</p>
          <p>房间：{{scope.row.roomNum}}</p>
          <p>方位：{{scope.row.installPositionName}}</p>
          </div>
          </template>
          </el-table-column>
          
          <el-table-column prop="detailAddress" label="地址" fixed="right"> </el-table-column>
        </el-table>

        <el-pagination
          :page-size="pageSize"
          :pager-count="11"
          layout="prev, pager, next"
          :total="sqDeviceTotal"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      config: {
        // header: ["告警时间", "告警类型", "告警设备", "告警地址", "方位", "监控"],
        // header: ["时间", "类型", "设备", "地址"],
        // header: ["编号","名称","设备数", "地址"],
        header: ["方舱名称","状态", "设备", "方舱地址"],
        data: [],
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        columnWidth: [180,120,80,300],
        rowNum: 10,
        index: false,
        align: ["center", "center", "center", "center", "center", "center"],
        hoverPause: false,
        waitTime: 2000,
      },
      total: 0,
      pageSize:10,
      untreatedCount: 0,
      alreadyProcessedCount: 0,
      list: [],
      squarecabinList: [],
      sqDeviceList: [],
      sqDeviceTotal:0,
      currentSqCabin:{},
      dashUrl:"",
      dashUser:{}
    };
  },
  components: {},
  props: {
    obj: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    obj: {
      handler() {},
      immediate: true,
      deep: true,
    },
  },
    
  created() {
    let dashUser = localStorage.getItem("dashUser");
    if(dashUser){
      this.dashUser = JSON.parse(dashUser);
    }
    let dashUrl = localStorage.getItem("dashUrl");
    if(dashUrl){
      this.dashUrl = dashUrl;
      this.getSquarecabinList();
    }
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.getSquarecabinList();
    },120000);
  },
  destroyed() {
    this.dateTimer && clearInterval(this.dateTimer);
    this.timer && clearInterval(this.timer);
  },
  methods: {
    click(e) {
      console.log("click 展示方舱下的设备", e);
      this.dialogVisible = true;
      let item = this.squarecabinData.list[e.rowIndex];
      this.getSqDeviceList(item);
      this.currentSqCabin = item;
      this.currentSqCabin.title = this.currentSqCabin.squareName + "方舱关联设备"  
      // +"（地址："+  this.currentSqCabin.location;
      this.$nextTick(()=>{
        document.querySelectAll(".jg-file-viewer")[0].parentNode.classList.add("jg-file-viewer")
      })
    },
    handleCurrentChange(e){
    console.log("click handleCurrentChange", e);
    this.getSqDeviceList(this.currentSqCabin,e)
    },
    getSqDeviceList(item,e=1){
      let dashUrl = this.dashUrl + "/api/dev/devonlinedevice/page";
      let params = {
        page: e,
        limit: this.pageSize,
        squareCabinId: item.squareId,
        regionType:this.dashUser.type
      };
      this.$get(dashUrl, params, {}, true).then(({ data: res }) => {
        console.log("getSquarecabinList data ", res);
        if (res) {
          this.sqDeviceList = res.list;
          this.sqDeviceTotal = res.total;
        }
      })
    },

    getSquarecabinList() {
      let dashUrl = this.dashUrl + "/api/dev/squarecabin/page";
      let dashUser = this.dashUser;
      let params = {
        page: 1,
        limit: 10000,
        regionType: dashUser.type,
        // provinceId: dashUser.provinceId,
      };
      switch (dashUser.type) {
          case 1:
            params.provinceId = dashUser.provinceId;
            break;
          case "city":
            params.cityId = dashUser.cityId;
            break;
          case "country":
            params.countryId = dashUser.countryId;
            break;
        }

      this.$get(dashUrl, params, {}, true).then(({ data: res }) => {
        console.log("getSquarecabinList data ", res);
        if (res) {
          this.squarecabinData = res;

          let newData = res;
          this.total = newData.total;
          // this.untreatedCount = newData.untreatedCount;
          // this.alreadyProcessedCount = newData.alreadyProcessedCount;
          var arr = [];
          newData.list.forEach((element) => {
            arr.push([
              `<div class='d-flex flex-column jc-center ai-center w-100 h-100 alarm-time' style='color:#11d0c9;'><span>${element.squareName}</span></div>`,
              `<span class="alarm-device" style='color:#11d0c9;'>${element.status=='ONLINE'?'在线':'待机'}</span>`,
              `<span class="alarm-device" style='color:#11d0c9;'>${element.smokeSensationCount}</span>`,
              `<div class='d-flex jc-center ai-center w-100 h-100'><span class='alarm-address' style='color:#11d0c9;width:300px' title='${element.location}'>${element.location}</span></div>`,
              // `<span class="alarm-device" style='color:#11d0c9;'>${element.installPositionName}</span>`,
              // `<span style="color:#11d0c9">▶</span>`
            ]);
          });

          this.list = newData.list;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.alarmRef.updateRows(arr);
            }, 0);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.alarm-address {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-time {
  font-size: 0.15rem;
  line-height: 0.2rem !important;

}
.alarm-device {
  font-size: 0.15rem;
}
</style>
<style lang="scss" scoped>
#alarmList {
  padding: 0.2rem;
  width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  .bg-color-black {
    width: 100%;
    height: 100%;
    position: relative;
    .top-data {
      position: absolute;
      top: -0.8rem;
      left: 0;
      height: 0.5rem;
      &-text {
        color: #11d0c9;
        font-size: 0.1rem;
        font-weight: bold;
      }
    }
  }
  .text {
    font-size: 0.2rem;
    color: #11d0c9;
  }
  .btn {
    height: 100%;
    white-space: nowrap;
    font-size: 0.1rem;
    color: #c3cbde;
  }
  .body-box {
    height: 100%;
    overflow: hidden;
  }
}
el-dialog {
  background: blue;
}
.jg-file-viewer{
  z-index:99999 !important;
}
</style>
