<template>
  <div id="alarmList">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex ai-center py-2 pl-2 w-full " style="position:relative">
        <div class="d-flex ai-center ">
        <span style="color:#5cd9e8">
          <icon name="fire"></icon>
        </span>
        <div class="d-flex ai-center">
          <span class="text mx-2">设备列表</span>
        </div>
        </div>
        <div class="text mx-2  " @click="dialogVisible=true" style="position:absolute;right:0;">更多<i class="el-icon-more-outline"></i></div>
      </div>
      <div class="d-flex jc-center flex-1 body-box">
        <dv-scroll-board ref="alarmRef" :config="config" style="width:100%;height:24vh;cursor:pointer;" @click="click" />
      </div>
    </div>

    <deviceListDialog v-if="dialogVisible" @close="dialogVisible=false"></deviceListDialog>


  </div>
</template>

<script>
import deviceListDialog from "./deviceListDialog";
export default {
  components:{
    deviceListDialog
  },
  data() {
    return {
      dialogVisible:false,
      config: {
        // header: ["告警时间", "告警类型", "告警设备", "告警地址", "方位", "监控"],
        header: [ "设备", "地址"],
        data: [],
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        columnWidth: [200,355],
        rowNum: 3,
        index: false,
        align: ["left", "left", "center", "center", "center", "center"],
        hoverPause: true,
        waitTime: 1000
      },
      total: 0,
      untreatedCount: 0,
      alreadyProcessedCount: 0,
      list: [],
      // 设备状态:1->正常;2->离线;3->预警;4->火警;5->故障;10->救援告警;
      deviceStatusOptions: [{
          value: 1,
          label: '在线'
        }, {
          value: 2,
          label: '待机'
        }, {
          value: 3,
          label: '预警'
        }, {
          value: 4,
          label: '火警'
        }, {
          value: 5,
          label: '故障'
        }, {
          value: 10,
          label: '救援告警'
        }
        ],
        deviceStatus: '',
        DeviceList:[],
        DeviceTotal:0,
        pageSize:10,
    };
  },
  mounted() {
    this.getSqDeviceList();
  },
  props: {
    obj: {
      type: Object,
      default () {
        return {}
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    obj: {
      handler(newData) {

        if(!newData.total){
            return;
        }
        this.total = newData.total;
        this.untreatedCount = newData.untreatedCount;
        this.alreadyProcessedCount = newData.alreadyProcessedCount;
        var arr = [];

        if(newData.list){
          newData.list.forEach(element => {
          arr.push([
            `<span class="alarm-device" style='color:#11d0c9;'>${element.productName}</span>`, 
            `<div class='d-flex jc-center ai-center w-100 h-100'><span class='alarm-address' style='color:#11d0c9;width:300px;text-align:left;' title='${element.detailAddress}'>${element.detailAddress} (${element.installPositionName})</span></div>`, 
            // `<span class="alarm-device" style='color:#11d0c9;'>${element.installPositionName}</span>`,
            // `<span style="color:#11d0c9">▶</span>`
          ]);
        });
        this.list = newData.list;
        }
        
        // var obj = JSON.parse(JSON.stringify(this.config));
        // obj.data = arr;
        // this.config = JSON.parse(JSON.stringify(obj));
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.alarmRef.updateRows(arr);
          }, 0);
        });
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    click (e) {
      if (!this.isAdmin) {
        // this.list[e.rowIndex]
        this.$emit('alarmDetail');
      }
      this.$emit('playMonitor', this.obj.deviceList[e.rowIndex].monitorDeviceBaseInfoVO?.previewUrlHd);
    },

    handleCurrentChange(e){
      console.log("click handleCurrentChange", e);
      this.getSqDeviceList('',e)
    },
    getSqDeviceList(item,e=1){
    let dashUser = localStorage.getItem("dashUser");
    let dashUrl = localStorage.getItem("dashUrl") + "/api/dev/devonlinedevice/page";
      let params = {
        page: e,
        limit: this.pageSize,
        // squareCabinId: item.squareId,
        regionType:dashUser.type,
        deviceStatus: this.deviceStatus
      };
      switch (dashUser.type) {
          case 1:
            params.provinceId = dashUser.provinceId;
            break;
          case "city":
            params.cityId = dashUser.cityId;
            break;
          case "country":
            params.countryId = dashUser.countryId;
            break;
        }
      this.$get(dashUrl, params, {}, true).then(({ data: res }) => {
        console.log("getSquarecabinList data ", res);
        if (res) {
          this.DeviceList = res.list;
          this.DeviceTotal = res.total;
        }
      })
    },
  }
};
</script>

<style lang="scss">
.alarm-address {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-time {
  font-size: 0.15rem;
  line-height: 0.2rem !important;
}
.alarm-device {
  font-size: 0.15rem;
}
</style>
<style lang="scss" scoped>
#alarmList {
  padding: 0.2rem;
  width: 100%;
  // height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  .bg-color-black {
    width: 100%;
    height: 100%;
    position: relative;
    .top-data {
      position:absolute;
      top:-0.8rem;
      right: 0;
      height:0.5rem;
      &-text {
        color: #11d0c9;
        font-size: 0.1rem;
        font-weight: bold;
        line-height:0.1rem;
      }
    }
  }
  .text {
    font-size: 0.2rem;
    color: #11d0c9;
  }
  .btn {
    height: 100%;
    white-space: nowrap;
    font-size: 0.1rem;
    color: #c3cbde;
  }
  .body-box {
    height: 100%;
    overflow: hidden;
  }
}
</style>