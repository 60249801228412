<template>
  <div
    class="echarts d-flex a-center jc-center"
    style="position: relative; overflow: hidden"
  >
    <div class="data d-flex py-3 jc-between map-header" >
      <div class="d-flex flex-column py-2 px-3 item" @click="deviceStatusFunc(4)">
        <dv-border-box-13 class="item-box" >
          <div class="text">火警总数</div>
          <div class="text t2 mb-2">{{ fireAlarmCount }}</div>
        </dv-border-box-13>
      </div>
      <div class="d-flex flex-column a-center py-3 px-3 item" @click="deviceStatusFunc(5)">
        <dv-border-box-13 class="item-box" >
          <div class="text">故障总数</div>
          <div class="text t2 pb-2">
            {{ malFunctionCount ? malFunctionCount : 0 }}
          </div>
        </dv-border-box-13>
      </div>
      <div class="d-flex flex-column a-center py-3 px-3 item" @click="deviceStatusFunc()">
        <dv-border-box-13 class="item-box" @click="deviceListShow=true">
          <div class="text">设备总数</div>
          <div class="text t2">{{ totalCount }}</div>
        </dv-border-box-13>
      </div>
    </div>

    <deviceListDialog :devStatus="devStatus"  v-if="deviceListShow" @close="deviceListShow=false"></deviceListDialog>

    <div
      id="map"
      ref="allMap"
      style="width: 48vw; height: 100vh; padding: 0 2vw"
    />
    <div class="mapChoose">
      <span v-for="(item, index) in parentInfo" :key="item.code">
        <span class="text" @click="chooseArea(item, index)">{{
          item.cityName == "全国" ? "中国" : item.cityName
        }}</span>
        <span v-show="index + 1 != parentInfo.length" class="icon">></span>
      </span>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
// import resize from '@/mixins/resize.js'


import deviceListDialog from "@/views/deviceListDialog";
export default {
  components:{
    deviceListDialog
  },
  name: "AllMap",
  // mixins: [resize],
  data() {
    return {
      deviceListShow:false,
      fireAlarmCount: 0,
      totalCount: 0,
      malfunctionCount: 0,
      myCharts: null,
      geoJson: {
        features: [],
      },
      parentInfo: [
        {
          cityName: "全国",
          code: 100000,
          level: "",
        },
      ],
      timeTitle: ["2021"],
      // regionLevels: ["province", "city", "country", "area"],
      regionLevels: ["province", "city", "country", "area",'distinct'],
      provinceData: [],
      cityData: [],
      countryData: [],
      currentLatlng: ["100.83531246", "30.0267395887"],
      // currentLatlng:[],
      userInfo: {},
      areaId: null,
      regionType: null,
      dashUser: {},
      dashUrl: "",
      currentAreaJson: {},
      areaData: null,
      devStatus:''
    };
  },
  created() {
    this.timeTitle[0] = new Date().getFullYear();

    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
        if(this.parentInfo.length==1){
          this.getDashTotal();
        }
    },60000);
  },
  mounted() {
    let dashUrl = localStorage.getItem("dashUrl");
    if (dashUrl) {
      this.dashUrl = dashUrl;
    }
    let dashUser = localStorage.getItem("dashUser");
    dashUser = JSON.parse(dashUser);
    if (dashUser) {
      this.dashUser = dashUser;
      this.regionType = dashUser.type;
      this.initRegion();
    } else {
      this.getGeoJson(100000, "province");
    }

    let userInfo = localStorage.getItem("userInfo");
    this.userInfo = JSON.parse(userInfo);
  },
  destroyed() {
    this.dateTimer && clearInterval(this.dateTimer);
    this.timer && clearInterval(this.timer);
  },
  methods: {
    deviceStatusFunc(status){      
      this.devStatus = status
      this.deviceListShow = true
    },
    getGeoJson(adcode, level = "province") {
      const that = this;
      let adcodeMap = adcode;
      console.log(`${level}:adcodeMap`, adcodeMap, this.currentAreaJson);

      Promise.all([this.getDevStatisData(adcodeMap, level)]).then((res) => {
        console.log("判断使用的地图数据方案：", res);
        const statsList = this.regionType
          ? res[0]
          : res[0].filter((obj) => obj && obj.id !== 0);

        setTimeout(
          () => {
            let AMapUI = window.AMapUI;
            AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
              const districtExplorer = new DistrictExplorer();
              districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
                if (error) {
                  console.error("amap error", error);
                  return;
                }
                let Json = areaNode ? areaNode.getSubFeatures() : [];
                if (this.currentAreaJson) {
                  Json = this.currentAreaJson.features;
                  console.log("调用街道数据");
                }
                if (Json.length > 0) {
                  that.geoJson.features = Json;
                } else if (Json.length === 0) {
                  that.geoJson.features = that.geoJson.features.filter(
                    (item) => item.properties.adcode == adcodeMap
                  );
                  if (that.geoJson.features.length === 0) return;
                }
                that.getMapData(statsList, level);
              });
            });
          },
          (this.parentInfo.length > 2 || this.dashUser.type>1)? 1100 : 0
        );
      });
    },
    setMapData(statsList, level) {
      this.getMapData(statsList, level);
    },
    getAreaGeoJson(adcode) {
      this.$get(
        this.dashUrl + "/api/other/getTownGeometry",
        {
          adcode: adcode,
        },
        {},
        true
      ).then(({ data: res }) => {
        this.currentAreaJson = res;
        if (this.regionType == 3) {
          // this.geoJson = res;
        }
      });
    },
    // 获取数据
    getMapData(statsList, level = "province") {
      let timeOut = 0;
      if (this.currentAreaJson) {
        timeOut = 0;
      }
      setTimeout(() => {

        if (this.currentAreaJson.features) {
          this.geoJson.features = this.currentAreaJson.features;
        }
        let features = this.geoJson.features;
        console.log("ff", features, this.currentAreaJson);
        const mapData = {};
        const pointData = {};
        const sum = {};
        const sumTotalCount = {};
        const sumMalfunctionCount = {};
        this.timeTitle.forEach((item) => {
          mapData[item] = [];
          pointData[item] = [];
          sum[item] = 0;
          sumTotalCount[item] = 0;
          sumMalfunctionCount[item] = 0;
          features.map((j) => {
            let statsData = statsList
              ? statsList.filter(function (item) {
                  
                  // 临时处理 街道数据有问题 编码可能不对应或者缺失 
                  if(j.properties.name.indexOf('服务中心')!=-1){
                    j.properties.name = j.properties.name.replace('服务中心','')
                  }

                  return (
                    (j.properties.adcode && item.regionId === j.properties.adcode) 
                    ||
                    (
                    (
                    (j.properties.code&&j.properties.code.toString().length==9&&item.regionId==j.properties.code)
                    ||item.regionName.indexOf(j.properties.name)!=-1
                    ||(j.properties.name.indexOf('镇')!=-1
                      &&item.regionName==j.properties.name.replace('镇','街道'))
                    )
                    // || (j.properties.code.toString().length==9&&item.regionId == j.properties.code)
                    )

                  );

                })
              : [];

            statsData = statsData[0];

            const fireAlarmCount = statsData ? statsData.fireAlarmDeviceCount : 0;
            const totalCount = statsData ? statsData.totalDevicesCount : 0;
            const malfunctionCount = statsData ? statsData.malfunctionDeviceCount : 0;
            const squareCabinCount = statsData?statsData.squareCabinCount:0;  

            mapData[item].push({
              name: j.properties.name,
              value: fireAlarmCount,
              cityCode: j.properties.adcode
                ? j.properties.adcode
                : j.properties.code.slice(0, 6),
              level: level,
              totalCount: totalCount,
              malfunctionCount: malfunctionCount ? malfunctionCount : 0,
              squareCabinCount:squareCabinCount?squareCabinCount:0
            });
            if (j.properties) {
              if (!j.properties.center) {
                j.properties.center = j.properties.centroid;
              }
              pointData[item].push({
                name: j.properties.name,
                value: [
                  j.properties.center[0],
                  j.properties.center[1],
                  // fireAlarmCount,
                  totalCount
                ],
                cityCode: j.properties.code
                  ? j.properties.code
                  : j.properties.adcode,
                level: level,
                totalCount: totalCount,
                malfunctionCount: malfunctionCount,
              });
            }

            sum[item] += fireAlarmCount;
            sumTotalCount[item] += totalCount;
            sumMalfunctionCount[item] += malfunctionCount;

            if (this.dashUser.type != 2) {
              this.fireAlarmCount = sum[item];
              this.totalCount = sumTotalCount[item];
              if (sumMalfunctionCount[item]) {
                this.malfunctionCount = sumMalfunctionCount[item];
              }
            }
            if (this.areaData) {
              let totalCount = 0;
              let fireAlarmCount = 0;
              let malfunctionCount = 0;
              this.areaData.length > 0 &&
                this.areaData.forEach((item) => {
                  totalCount += item.totalDevicesCount;
                  malfunctionCount += item.totalMalfunctionDeviceCount;
                  fireAlarmCount += item.totalFireAlarmDeviceCount;
                });
              this.totalCount = totalCount;
              this.malfunctionCount = malfunctionCount;
              this.fireAlarmCount = fireAlarmCount;
            }
          });

          mapData[item] = mapData[item].sort(function (a, b) {
            return b.value - a.value;
          });
        });

        this.initEcharts(
          mapData,
          pointData,
          sum,
          sumTotalCount,
          sumMalfunctionCount
        );
      }, timeOut);
    },
    initEcharts(mapData, pointData, sum, sumTotalCount, sumMalfunctionCount) {
      this.myChart = echarts.init(this.$refs.allMap);
      console.log("initEcharts pointData", pointData);
      this.currentLatlng = this.geoJson.features[0].properties.center;
      if (this.parentInfo.length === 1) {
        echarts.registerMap("china", this.geoJson); // 注册
      } else {
        if (this.currentAreaJson.features) {
          this.geoJson.features = this.currentAreaJson.features;
        }
        echarts.registerMap("map", this.geoJson); // 注册
      }
      const option = {
        timeline: {
          show: false,
          data: this.timeTitle,
          axisType: "category",
          autoPlay: true,
          playInterval: 3000,
          width: "100%",
          label: {
            normal: {
              textStyle: {
                color: "rgb(179, 239, 255)",
              },
            },
            emphasis: {
              textStyle: {
                color: "#fff",
              },
            },
          },
          symbolSize: 10,
          lineStyle: {
            color: "#8df4f4",
          },
          checkpointStyle: {
            borderColor: "#8df4f4",
            color: "#53D9FF",
            borderWidth: 2,
          },
          controlStyle: {
            showNextBtn: false,
            showPrevBtn: false,
            normal: {
              color: "#53D9FF",
              borderColor: "#53D9FF",
            },
            emphasis: {
              color: "rgb(58,115,192)",
              borderColor: "rgb(58,115,192)",
            }
          },
        },
        baseOption: {
          animation: true,
          animationDuration: 300,
          animationEasing: "cubicInOut",
          animationDurationUpdate: 300,
          animationEasingUpdate: "cubicInOut",
          tooltip: {
            axisPointer: {
              type: "none", // 隐藏鼠标悬停时的区域横线，但保留提示框
            },
          },
          grid: {
            right: '2%',
            top: '12%',
            bottom: '8%',
            width: '0%'
          },
          geo: {
            map: this.parentInfo.length === 1 ? "china" : "map",
            zoom:
              this.parentInfo.length === 1
                ? this.dashUser && this.dashUser.type > 1
                  ? 15
                  : 2.3
                : 0.8,
            roam: true,
            center: this.parentInfo.length === 1 ? this.currentLatlng : false,
            tooltip: {
              trigger: "item",
              formatter: (p) => {
                let val = p.value[2];
                if (window.isNaN(val)) {
                  val = 0;
                }
                return "";
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#24CFF4",
                borderColor: "#53D9FF",
                borderWidth: 1.3,
                shadowBlur: 15,
                shadowColor: "rgb(58,115,192)",
                shadowOffsetX: 7,
                shadowOffsetY: 6,
              },
              emphasis: {
                areaColor: "#8dd7fc",
                borderWidth: 1.6,
                shadowBlur: 25,
              },
            },
            label: {
              show: true,
              fontSize: 12,
              color: "#FFFFFF",
            },
          },
        },
        options: [],
      };
      this.timeTitle.forEach((item) => {
        const xData = [];
        const yData = [];
        let min = mapData[item][mapData[item].length - 1].value;
        const max = mapData[item][0].value;

        mapData[item].forEach((c) => {
          xData.unshift(c.name);
          yData.unshift(c.value);
        });
        console.log(
          "",
          sum[item],
          sumMalfunctionCount[item],
          sumTotalCount[item]
        );
        let doctItem = {
          name: "散点",
          type: "effectScatter",
          coordinateSystem: "geo",
          rippleEffect: {
            brushType: "fill",
          },
          itemStyle: {
            normal: {
              color: "#F4E925",
              shadowBlur: 0.8,
              shadowColor: "#333",
            },
          },
          data: pointData[item].filter((point) => {
            return point.totalCount > 0;
          }),
          symbolSize: function (val) {
            const value = val[2];
            if (value >= max) {
              return 12;
            }
            return 10;
          },
          showEffectOn: "render", // 加载完毕显示特效
        };
        let doctItem2 = {
          name: "散点2",
          type: "effectScatter",
          coordinateSystem: "geo",
          rippleEffect: {
            brushType: "fill",
          },
          itemStyle: {
            normal: {
              color: "#F4E925",
              shadowBlur: 0.8,
              shadowColor: "#333",
            },
          },
          data: pointData[item].filter((point) => {
            return point.totalCount > 1000;
          }),
          symbolSize: function (val) {
            const value = val[2];
            if (value >= max) {
              return 22;
            }
            return 20;
          },
          showEffectOn: "render", // 加载完毕显示特效
        };
        console.log("doctItem",doctItem,doctItem2)
        let doctItem3 =  {
            name: "设备数",
            type: "scatter",
            coordinateSystem: "geo",
            symbol: "circle",
            symbolSize: function (val) {
              return val?20:18;
            },
            label: {
              // show:true,
              normal: {
                show: true,
                formatter: function (value) {
                  return value.value[2];
                },
                textStyle: {
                  color: "#11d0c9",
                  fontSize: 12,
                },
              },
            },
            itemStyle: {
              normal: {
                color: "#F4E925", //标志颜色
              },
            },
            zlevel: 6,
            data: pointData[item].filter((point) => {
              return point.totalCount > 0;
            })
          };
        option.options.push({
          visualMap: {
            show: false,
            min: min,
            max: max,
            left: "3%",
            bottom: "5%",
            calculable: true,
            seriesIndex: [0],
            inRange: {
              color: ["#24CFF4", "#2E98CA", "#1E62AC"],
            },
            textStyle: {
              color: "#24CFF4",
            },
          },
          xAxis: {
            type: "value",
            scale: true,
            position: "top",
            boundaryGap: false,
            show: false,
            splitLine: {
              show: false,
            }
          },
          yAxis: {
            type: "category",
            nameGap: 1,
            show: false,
            data: xData
          },
          series: [
            {
              name: item + "设备",
              type: "map",
              geoIndex: 0,
              map: this.parentInfo.length === 1 ? "china" : "map",
              roam: true,
              zoom: 1,
              tooltip: {
                trigger: "item",
                formatter: (p) => {
                  let val = p.value ? p.value : 0;
                  // console.log("yyyy",p.data)
                  if (!p.data) {
                    p.data = {
                      totalCount: 0,
                      malfunctionCount: 0,
                    };
                    this.areaData.forEach((item) => {
                      if (item.regionName == p.name) {
                        p.data.totalCount = item.totalDevicesCount;
                        p.data.malfunctionCount = item.malfunctionCount;
                      }
                    });
                  }

                  const totalCount = p.data.totalCount ? p.data.totalCount : 0;
                  // const malfunctionCount = p.data.malfunctionCount
                  //   ? p.data.malfunctionCount
                  //   : 0;
                  // console.log("malfunctionCount",malfunctionCount)
                  const squareCabinCount = p.data.squareCabinCount
                   ? p.data.squareCabinCount
                    : 0;  
                  if (p.name == "南海诸岛") return;
                  if (window.isNaN(val)) {
                    val = 0;
                  }
                  const txtCon =
                    "<div>" +
                    p.name +
                    "&nbsp;设备总数：" +
                    totalCount +
                    // "&nbsp;故障总数：" +
                    // malfunctionCount +
                    "&nbsp;方舱总数：" +    
                    squareCabinCount +
                    "&nbsp;火警总数：" +
                    val +
                    "</div>";
                  return txtCon;
                },
              },
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgb(0,0,0) ",
                  },
                },
              },
              data: mapData[item],
            },
            {
              type: "bar",
              barGap: "-100%",
              barCategoryGap: "55%",
              data: yData,
            },
            doctItem,
            doctItem2,
            doctItem3
          
          ],
        });
      });
      this.myChart.setOption(option, true);

      this.myChart.off("click");
      this.myChart.on("click", this.echartsMapClick);
    },

    // 点击下钻
    echartsMapClick(params) {
      console.log("echartsMapClick", params);
      if (!params.data) {
        return;
      }
      if(this.parentInfo.length==3||params.data.cityCode.toString().length>6){
        console.log("省市区-到街道")
        return;
      }
      if (
        this.parentInfo[this.parentInfo.length - 1].code ==
          params.data.cityCode &&
        this.regionType != 3
      ) {
        return;
      }
      const data = params.data;
      if (this.dashUser.type != 3) {
        this.parentInfo.push({
          cityName: data.name,
          code: data.cityCode,
          level: data.level,
        });
      }
      let nextLevel =
        this.regionLevels[this.getRegionIndexByLevel(params.data.level) + 1];
      this.getGeoJson(data.cityCode, nextLevel);
    },
    initRegion() {
      let dashUser = this.dashUser;
      this.parentInfo = [
        {
          cityName: "",
          code: "",
          level: "",
        },
      ];
      this.parentInfo[0].cityName = "区域";
      switch (dashUser.type) {
        case 1:
          this.parentInfo[0].cityName = dashUser.province;
          this.parentInfo[0].code = dashUser.provinceId;

          this.getGeoJson(dashUser.provinceId, "city");
          break;
        case 2:
          this.parentInfo[0].cityName = dashUser.city;
          this.parentInfo[0].code = dashUser.cityId;
          this.getGeoJson(dashUser.cityId, "city");
          break;
        case 3:
          this.parentInfo[0].cityName = dashUser.country;
          this.parentInfo[0].code = dashUser.countryId;

          this.getGeoJson(dashUser.cityId, "city");
          // this.getAreaGeoJson(dashUser.countryId);
          // this.getGeoJson(dashUser.countryId, "country");
          setTimeout(() => {
            this.areaId = dashUser.countryId;
            this.getGeoJson(dashUser.countryId, "country");

          }, 800);
          break;
          case 4:
          break;
      }

      setTimeout(() => {
        this.getDashTotal();
      }, 800);
    },
    towMap(adcode){
      this.myChart = echarts.init(this.$refs.allMap);
      let geoJson = this.currentAreaJson;
      geoJson.features = geoJson.features
      .filter(item=> {return item.properties.code==adcode})
      echarts.registerMap("map", geoJson); // 注册
      let option = {
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataView: { readOnly: false },
          restore: {},
          saveAsImage: {}
        }
      },
      series: [
        {
          name: '',
          type: 'map',
          map: 'map',
          label: {
            show: true
          },
          data: [],
        }
      ]
    };
    this.myChart.setOption(option, true);
    },
    getDashTotal() {
      let dashboardUrl = "/api/dashboard/dashboard";
      if (this.dashUser.type != 2) {
        return;
      }
      let params = {
        regionType: this.dashUser.type,
      };
      this.$get(this.dashUrl + dashboardUrl, params, {}, true).then((res) => {
        this.fireAlarmCount = res.data.totalFireAlarmDeviceCount;
        this.totalCount = res.data.totalDevicesCount;
      });
    },

    // 选择切换市县
    chooseArea(val, index) {
      if (index == 0 && this.regionType) {
        this.initRegion();
        return;
      }
      if (this.parentInfo.length === index + 1) {
        return;
      }
      this.parentInfo.splice(index + 1);
      const parentInfo = this.parentInfo[this.parentInfo.length - 1];
      let nextLevel =
        this.regionLevels[this.getRegionIndexByLevel(parentInfo.level) + 1];
      this.getGeoJson(parentInfo.code, nextLevel);
    },
    getRegionIndexByLevel(level) {
      for (let i = 0; i < this.regionLevels.length; i++) {
        if (this.regionLevels[i] === level) {
          return i;
        }
      }
      return -1;
    },
    getDevStatisProvinceData() {
      return new Promise((resolve) => {
        this.$get(this.$apis.devstatisprovince, {
          page: 1,
          limit: 100,
        })
          .then(({ data: res }) => {
            let list = res.list;
            list.map((item) => {
              item.id = item.provinceId;
              if (item.province) {
                list.push(item);
              }
            });
            resolve(list);
          })
          .catch((e) => {
            console.error(e);
            resolve([]);
          });
      });
    },
    getDevStatisCityData(provinceId) {
      return new Promise((resolve) => {
        this.$get(this.$apis.devstatiscity, {
          provinceId: provinceId,
          page: 1,
          limit: 100,
        })
          .then(({ data: res }) => {
            res.list.map((item) => {
              item.id = item.cityId;
              return item;
            });
            resolve(res.list);
          })
          .catch((e) => {
            console.error(e);
            resolve([]);
          });
      });
    },
    getDevStatisCountryData(cityId) {
      return new Promise((resolve) => {
        this.$get(this.$apis.devstatiscountry, {
          cityId: cityId,
          page: 1,
          limit: 100,
        })
          .then(({ data: res }) => {
            if (res.total == 0) {
              let list = [];
              res.list = this.cityData;
              res.list.map((item) => {
                if (item.countryId == cityId) {
                  list.push(item);
                }
              });
              res.list = list;
              resolve(list);
            } else {
              this.cityData = res.list;
              res.list.map((item) => {
                item.id = item.countryId;
                return item;
              });
              resolve(res.list);
            }
          })
          .catch((e) => {
            console.error(e);
            resolve([]);
          });
      });
    },
    getDevStatisData(id, level) {
      let res;
      console.log("getDevStatisData level", level, id);
      if (this.regionType) {
        res = this.getDashMapData(level, id);
        return res;
      }
      switch (level) {
        case "province":
          res = this.getDevStatisProvinceData();
          break;
        case "city":
          res = this.getDevStatisCityData(id);
          break;
        case "country":
          res = this.getDevStatisCountryData(id);
          break;
        case "area":
          this.areaId = id;
          res = this.getDevStatisCountryData(id);
          break;
      }
      return res;
    },
    getDashMapData(level, id) {
      console.log("getDashMapData ", level, id);
      if (id && id.toString().slice(2, 4) != "00") {
        level = "country";
      }
      if (id && id.toString().slice(4, 6) != "00") {
        level = "area";
      }
      if (level == "area") {
        this.getAreaGeoJson(id);
      }

      return new Promise((resolve) => {
        let dashUrl = this.dashUrl + "/api/dashboard/devicesStaticByRegion";
        let params = {
          //  regionType: this.regionType
        };
        if (this.regionType != 3) {
          this.currentAreaJson = {};
        }
        this.areaData = null;
        switch (level) {
          case "province":
            params.regionType = 1;
            params.provinceId = id;
            break;
          case "city":
            params.regionType = 1;
            params.provinceId = id;
            break;
          case "country":
            params.regionType = 2;
            params.cityId = id;
            break;
          case "area":
            params.regionType = 3;
            params.countryId = id;
            console.log("区县数据----", params);
            break;
        }

        let code = id;
        if (code.length === 6) {
          var cityCode = code.slice(2, 4);
          var districtCode = code.slice(4, 6);
          if (cityCode === "00" && districtCode === "00") {
            // return '省级';
            // params.regionType =1;
            // params.provinceId = id;
          } else if (districtCode === "00" && level == "city") {
            // return '市级';
            params.regionType = 2;
            params.cityId = id;
          } else {
            // return '区级';
            // params.regionType =1;
            // params.countryId = id;
          }
        }

        this.$get(dashUrl, params, {}, true)
          .then(({ data: res }) => {
            if (res) {
              if (level == "area") {
                this.areaData = res;
              }
              resolve(res);
            }
          })
          .catch((e) => {
            console.error(e);
            resolve([]);
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
  // background: url("../../assets/img/bg1.jpg") no-repeat;
  background-size: 100% 100%;
}
.mapChoose {
  position: absolute;
  left: 20px;
  // top: 55px;
  left: 0px;
  top: -6px;
  color: #eee;
  z-index: 99999;

  margin-left: 5vw;
  padding-top:0.05rem;

  .title {
    padding: 5px;
    border-top: 1px solid rgba(147, 235, 248, 0.8);
    border-bottom: 1px solid rgba(147, 235, 248, 0.8);
    cursor: pointer;
  }

  .icon {
    font-family: "simsun";
    font-size: 25px;
    margin: 0 11px;
    color: #11d0c9;
  }
}
.text {
  color: #11d0c9;
  font-size: 0.3rem;
  line-height: 0.45rem;
  text-align: center;
}
.t2 {
  font-size: 0.6rem;
  font-weight: bold;
  margin: 6px 0;
}
.map-header {
  position: absolute;
  top: 24px;
  left: 4.5vw;
  width: 38vw;
  height: 150px;
  z-index: 9999;
  margi: 20px;
  border: 20px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .item {
    width: 12vw;
    align-items: center;
    justify-content: center;
  }
  .item-box {
    height: 130px;
    width: 12vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    div{
      margin-top:10px !important;
    }
  }
}
</style>
