<template>
  <div class="w-100 h-100">
    <Chart :cdata="cdata" :isAdmin="isAdmin" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {};
  },
  components: {
    Chart,
  },
  mounted () {
    
  },
  props: {
    cdata: {
      type: Object,
      default () {
        return {}
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
</style>