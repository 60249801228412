<template>
  <div id="access">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex jc-center flex-1 h-100">
        <squarecabinList v-if="dashToken"></squarecabinList>
        <accessChart :cdata="cdata" :isAdmin="isAdmin" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import accessChart from "@/components/echart/accessChart";
import squarecabinList from "./squarecabinList.vue";
export default {
  data() {
    return {
      dashToken:localStorage.getItem("dashToken"),
      cdata: {
        category: [],
        bar1Data: [],
        bar2Data: [],
      }
    };
  },
  components: {
    accessChart,
    squarecabinList
  },
  mounted() {
    
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: {
      handler(newData) {
        var category = [];
        var bar1Data = [];
        var bar2Data = [];
        newData.forEach(element => {
          category.push(element.town);
          bar1Data.push(element.towerCount);
          bar2Data.push(element.deviceCount);
        });
        this.cdata.category = category;
        this.cdata.bar1Data = bar1Data;
        this.cdata.bar2Data = bar2Data;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss">
#access {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    width: 100%;
    height: 100%;
  }
}
</style>